import React, { useState } from "react";
import {Banner} from "../components/SuryaLoan/Banner";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import { VscCombine } from "react-icons/vsc";
import features_img from "../images/features_img.png";
import process_line from "../images/process_line.svg";
import bg_shape from "../images/service_grid_bg.png";
import Number_Statistics from "../components/Number_Statistics";
import { AiOutlineFileDone } from "react-icons/ai";
import { VscOpenPreview } from "react-icons/vsc";
import { IoMdDoneAll } from "react-icons/io";
import LoanCalculator from "../components/LoanCalculator";
import ChatButton from "../components/ChatButton";
import underlay_img from "../images/get_started_underlay.jpg";
import overlay_img from "../images/get_started_overlay.jpg";
import { FaApple } from "react-icons/fa";
import { DiAndroid } from "react-icons/di";
import { BiSolidCustomize } from "react-icons/bi";
import { SiFsecure } from "react-icons/si";
import { FaStrava } from "react-icons/fa";
import { FaHandsClapping } from "react-icons/fa6";
import { FaDroplet } from "react-icons/fa6";
import { SiWebmoney } from "react-icons/si";
import {
  MdOutlineFileDownload,
  MdOutlineFileUpload,
  MdOutlineDone,
} from "react-icons/md";
import step_2_img from "../images/step_2.png";
import step_3_img from "../images/step_3.png";
import { About } from "../components/SuryaLoan/About";
import { Card } from "../components/SuryaLoan/Card";
import { Feature } from "../components/SuryaLoan/Feature";
import { FAQHome } from "../components/SuryaLoan/FAQHome";

import img1 from "../images/mdi_bike-fast_sl.png"
import img2 from '../images/ph_less-than-or-equal-bold.png'
import img3 from "../images/fluent_money-16-filled.png"
import mobilesl from "../images/mobilesl.png"
import mobiles2 from "../images/mobiles2.JPG"
import { aboutParagraph, aboutParagraphHidden, advantages, debtConsolidation, instantloan, secureFuture, shortloan, weWork } from "../components/content/text";

const Home = () => {
  const [step, setStep] = useState(1);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  
  return (
    <>
      <div className="page_wrapper">
        <div className="container">
        <Banner />
        </div>
        <About/>

        <div className="services_section">
        <div className='services_card_sl_title my-center'>
            <div className='services_card_sl_title-1'>What we do</div>
            <div className='services_card_sl_title-2'>Empowering You with <span className='title-theme-color'>Tailored</span></div>
            <div className='services_card_sl_title-2 title-theme-color'>Loan Services</div>
        </div>         
          <div className="services_card_container">
              <Card title={"Instant Loan"} icon={<GiPayMoney className="service_icon" />} img={img1} des={instantloan} link={'/instant-loan'}/>
              <Card title={"Short Loans"} icon={<GiTakeMyMoney className="service_icon" />} img={img2} des={shortloan} link={'/short-term-loan'}/>
              <Card title={"Det Consolidation"} icon={<VscCombine className="service_icon" />} img={img3} des={debtConsolidation} link={'/services'}/>
          </div>
        </div>

        {/* <div className="features_section">
          <div className="features_section_content ">
            <div>
              <div className="title_section">
                <p className="sub_title flex flex-center">
                  <span>
                    <BsFillGrid1X2Fill
                      className="mr10"
                      style={{ marginBottom: "-2px" }}
                    />
                  </span>
                  <span>Why Choose Us</span>
                </p>

                <h2 className="mt20">
                  Providing personal loans designed to{" "}
                  <span>secure your future success</span>
                </h2>
              </div>

              <div className="features_section_content_description mt20">
                <p>
                  Choosing SuryaLoan as your finance company ensures that you
                  receive exceptional service tailored to your financial needs.
                  Specializing in personal loans, we offer a seamless process
                  for obtaining cash loans online, allowing you to secure funds
                  quickly and efficiently. Whether you need an urgent loan
                  online or an urgent money loan, SuryaLoan provides reliable
                  and fast solutions to help you manage your financial
                  challenges with ease. Trust SuryaLoan for your personal
                  loan requirements and experience the convenience, peace of
                  mind that comes with our expert financial services.
                </p>
              </div>
            </div>
            <div className="features_image">
              <img src={features_img} alt="" />
            </div>
          </div>
          <Number_Statistics />

          <div className="general_working">
            <div className="title_section">
              <p className="sub_title flex flex-center justify-center">
                <span>
                 
                </span>
              </p>

              <h2 className="mt20 full-width text-center">
                How We <span>Work ?</span>
              </h2>
            </div>
            <div className="process_card_area mt50">
              <div className="process_line">
                <img src={process_line} alt="" />
              </div>
              <div className="process_row">
                <div className="process_card">
                  <div className="process_card_icon flex flex-center justify-center mb20">
                    <AiOutlineFileDone className="process_icon" />
                  </div>
                  <div className="process_card_number">01</div>
                  <h2 className="mb10">Application Submission</h2>
                  <p className="full-width text-center">
                    Fillout and submit a loan application with necessary
                    documents and details on our website
                  </p>
                </div>
                <div className="process_card">
                  <div className="process_card_icon flex flex-center justify-center mb20">
                    <VscOpenPreview className="process_icon" />
                  </div>
                  <div className="process_card_number">02</div>
                  <h2 className="mb10">Application Review</h2>
                  <p className="full-width text-center">
                    SuryaLoan reviews your application, conducts credit
                    checks, and verifies your documents.
                  </p>
                </div>
                <div className="process_card">
                  <div className="process_card_icon flex flex-center justify-center mb20">
                    <IoMdDoneAll className="process_icon" />
                  </div>
                  <div className="process_card_number">03</div>
                  <h2 className="mb10">Application Approval</h2>
                  <p className="full-width text-center">
                    Once verified, receive loan approval along with the terms
                    and conditions and loan repayment date
                  </p>
                </div>
                <div className="process_card">
                  <div className="process_card_icon flex flex-center justify-center mb20">
                    <GiTakeMyMoney className="process_icon" />
                  </div>
                  <div className="process_card_number">04</div>
                  <h2 className="mb10">Disbursal Initiation</h2>
                  <p className="full-width text-center">
                    Upon acceptance of the terms, the loan amount is disbursed
                    to your account directly. No hassle.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="advantages_wrapper">
      <div className="title_section">
        <h2 className="mt20 full-width text-center">
          Why Should You Choose <span>SuryaLoan</span>
        </h2>
        <p className="mt5 text-center">
          Multiple features make SuryaLoan stand out from the rest. Let’s have a look at the features in detail.
        </p>
      </div>
      <div className="advantages_section">
        <div className="container">
          <div className="advantages_section_row">
            {advantages.map((advantage, index) => (
              <div className="advantage_section_card" key={index}>
                <div className="advantage_section_icon_container">
                  <BiSolidCustomize className="advantage_icon" />
                </div>
                <div className="content">
                  <h3>{advantage.title}</h3>
                  <p style={{ textAlign: "justify" }}>
                    {advantage.content.split('. ').slice(0, 1)}{' '}
                    <a
                      style={{ color: "#26b9db", cursor: 'pointer' }}
                      onClick={() => handleToggle(index)}
                    >
                      {expandedIndex === index ? ' Read Less' : ' Read More'}
                    </a>
                  </p>
                  {expandedIndex === index && (
                    <div className="additional_content">
                      <p style={{ textAlign: "justify" }}>
                        {advantage.content.split('. ').slice(1).join('. ')}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
        </div> */}
        <Feature/>
        <div className="features_section">
          <div className="advantages_wrapper">
            <div className="title_section">
              <h2 className="mt20 full-width text-center">
                SuryaLoan's <span>Advantages</span>
              </h2>
              <p className="mt5 text-center">
                Here's how we make your financial journey smooth
              </p>
            </div>
            <div className="advantages_section">
              <div className="container">
                <div className="advantages_section_row text-center">
                  {advantages?.map((item, index)=>{
                    return (
                      <div data-aos="zoom-in-down" className="advantage_section_card" key={index}>
                    <div className="advantage_section_icon_container mx-auto">
                      {item?.icon}
                    </div>
                    <div>
                      <h3>{item?.title}</h3>
                      <p>
                        {item?.description}
                      </p>
                    </div>
                  </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="zoom-in-down" className="get_started_section">
          <div className="title_section ">
            <h2 className="mt20 full-width text-center">
              Get Started <span>Instantly</span>
            </h2>
            <p className="full-width text-center mt5">
              Download the SuryaLoan app and get moving
            </p>
          </div>
          <div className="get_started_row">
            {step === 1 ? (
              <>
                <div className="image_section">
                  <img src={mobilesl} alt="" className="underlay_img" />
                  {/* <img src={overlay_img} className="overlay_img" /> */}
                </div>
              </>
            ) : step === 2 ? (
              <>
                <div className="image_section">
                  <img src={mobiles2} alt="" />
                </div>
              </>
            ) : step === 3 ? (
              <>
                <div className="image_section">
                  <img src={step_3_img} alt="" />
                </div>
              </>
            ) : (
              ""
            )}
            <div className="steps_section">
              <h3 className="mb50">How does this App Work ?</h3>
              <div
                className={
                  step === 1 ? "step_item flex step_active" : "step_item flex"
                }
              >
                <div
                  className={
                    step === 1
                      ? "step_icon_container icon_active"
                      : "step_icon_container"
                  }
                  onClick={(e) => setStep(1)}
                >
                  <MdOutlineFileDownload className="step_icon" />
                </div>
                <div className="step_content">
                  <h4 className="content_title">Download the App and Apply</h4>
                  <p>
                    Head over to apply for loan section and fill your basic
                    details to get started
                  </p>
                </div>
              </div>
              <div
                className={
                  step === 2 ? "step_item flex step_active" : "step_item flex"
                }
              >
                <div
                  className={
                    step === 2
                      ? "step_icon_container icon_active"
                      : "step_icon_container"
                  }
                  onClick={(e) => setStep(2)}
                >
                  <MdOutlineFileUpload className="step_icon" />
                </div>
                <div className="step_content">
                  <h4 className="content_title">Upload Documents</h4>
                  <p>
                    Upload KYC Documents (PAN and Aadhar Card) income proof &
                    wait for verification
                  </p>
                </div>
              </div>
              <div
                className={
                  step === 3
                    ? "step_item flex step_active last_item"
                    : "step_item flex last_item"
                }
              >
                <div
                  className={
                    step === 3
                      ? "step_icon_container icon_active"
                      : "step_icon_container"
                  }
                  onClick={(e) => setStep(3)}
                >
                  <MdOutlineDone className="step_icon" />
                </div>
                <div className="step_content">
                  <h4 className="content_title">Get Approved Instantly</h4>
                  <p>
                    Post verification the disbursal process is Initiated
                    instantly
                  </p>
                </div>
              </div>
              <div className="app_download_button_container ml70">
                <p className="mb10">Download the app now</p>
                <div className="flex flex-center button_wrapper">
                  <Link to="">
                    <FaApple className="app_download_icon" />
                  </Link>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.SuryaLoan.SuryaLoan"
                    target="_blank"
                  >
                    <DiAndroid className="app_download_icon" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
       <FAQHome/>
      </div>
      <ChatButton />
    </>
  );
};

export default Home;
